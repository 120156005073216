<template>
  <div id="criteria-details-form">
    <v-card>
      <v-overlay :value="OverlayLoadingFlag">
        <v-progress-circular
          indeterminate
          color="#4A148C"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-title>
        <h2>{{ PageTitle }}</h2>
        <v-spacer></v-spacer>
        <router-link to="/national/individual-award/list">
          <v-btn
            color="blue"
            min-width="92"
            rounded
            medium
            class="font-size-h6 px-3 py-3 mr-3 white--text"
          >
            Back
          </v-btn>
        </router-link>
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          medium
          class="btn font-size-h6 px-3 py-3 mr-3 mr-3 white--text"
          >Refresh
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-center mt-3 mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="getTotalNatConIndividualAwardsDetails"
        >
          <h3 class="text-dark" align="center">Award Details</h3>
          <h4 class="purple--text">Award = {{ AwardTypeTitleTxt }}</h4>
          <h3 class="text-dark" align="center">Member Details</h3>
          <v-row wrap class="px-3 py-0">
            <v-col cols="12" sm="12" md="6" lg="6">
              <h4 class="text-primary">
                <span class="text-danger">*</span> Select Member
                <small class="text-dark-50"
                  >(Only Current Year Dues paid member)</small
                >
              </h4>
              <v-autocomplete
                :reduce="(option) => option.value"
                :loading="MemberIdOptionsLoading"
                :items="MemberIdOptions"
                :rules="MemberIdRules"
                v-model="MemberId"
                required
                outlined
                dense
                clearable
              >
                <template v-slot:selection="{ item }">
                  <img width="50" height="50" :src="item.photo" />{{
                    item.text
                  }}
                </template>
                <template v-slot:item="{ item }">
                  <img width="50" height="50" :src="item.photo" />{{
                    item.text
                  }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              v-if="Member.membership_id > 0"
            >
              <div>
                <member-details
                  cardTitle="Award Nominee Member"
                  :Member="Member"
                  class="mt-0 mb-8"
                >
                </member-details>
              </div>
            </v-col>
          </v-row>

          <h3 class="text-dark" align="center">Criteria Details</h3>
          <v-row>
            <v-col v-if="CriteriaAnswers.length == 0">
              <h5 class="text-center text-dark">Nil.</h5>
            </v-col>
            <v-col
              v-else
              cols="12"
              sm="12"
              lg="12"
              md="12"
              v-for="item in CriteriaAnswers"
              :key="item.SNo"
            >
              <template v-if="item.JudgingCriteriaDetailFlag">
                <h3 class="criteria-title">
                  {{ item.JudgingCriteriaDetail }}
                  <small>(score - {{ item.JudgingCriteriaScore }})</small>
                </h3>
                <p></p>
              </template>
              <label :for="item.AnswerId" class="criteria-question-text">
                <h6>
                  <span class="text-danger">* </span>
                  {{ item.CriteriaQuestionsText }}
                </h6>
              </label>
              <v-textarea
                :id="item.AnswerId"
                v-model="item.AnswerText"
                value=""
                :maxlength="item.CriteriaQuestionsCharsCount"
                :counter="item.CriteriaQuestionsCharsCount"
                :rules="AnswerRules"
                outlined
                solo
              >
              </v-textarea>
              <h6 class="text-dark mt-0">
                Add / Delete Attachment (max -
                {{ item.CriteriaQuestionsAttachmentMax }})
                <v-btn
                  icon
                  color="blue"
                  v-if="
                    item.CriteriaQuestionsAttachmentMax > item.AllProject.length
                  "
                  @click="addNewRow(item)"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </h6>

              <template v-if="item.AllProject.length > 0">
                <v-row class="ml-0">
                  <v-col
                    v-for="(data, index) in item.AllProject"
                    cols="12"
                    sm="12"
                    md="12"
                    lg="3"
                    xl="3"
                    :key="index"
                    v-if="data.AnswerEventId != ''"
                  >
                    <v-text-field
                      v-model="data.AnswerEventId"
                      v-show="showTextBox"
                    >
                    </v-text-field>

                    <v-card>
                      <v-card-text>
                        <h6 class="text-right">
                          <v-btn
                            v-if="data.AnswerEventId != ''"
                            icon
                            color="red"
                            @click="deleteRow(item.SNo, index)"
                          >
                            <v-icon>mdi-delete-circle-outline</v-icon>
                          </v-btn>
                        </h6>
                        <h6 align="center">
                          <viewer rebuild :images="data.AnswerEventPhoto">
                            <img
                              style="padding: 5px"
                              width="70"
                              height="70"
                              :src="src"
                              :key="src"
                              v-for="src in data.AnswerEventPhoto"
                            />
                          </viewer>
                        </h6>
                        <h6>
                          Event Date -
                          <span class="text-dark">{{
                            data.AnswerEventDate
                          }}</span>
                        </h6>
                        <h6>
                          Area-
                          <span class="text-dark">{{
                            data.AnswerPortfolioName
                          }}</span>
                        </h6>
                        <h6>
                          Event Name -
                          <span class="text-dark">{{
                            data.AnswerEventName
                          }}</span>
                        </h6>
                      </v-card-text>
                    </v-card>
                    <!-- <v-simple-table>
                        <template v-if="data.AnswerEventId != ''">
                          <thead>
                            <tr>
                              <th class="text-center">
                                <h6>Event Image</h6>
                              </th>
                              <th class="text-center">
                                <h6>Event Date</h6>
                              </th>
                              <th class="text-center">
                                <h6>Area</h6>
                              </th>
                              <th class="text-center">
                                <h6>Even Name</h6>
                              </th>
                              <th class="text-center">
                                <h6>Actions</h6>
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-center">
                            <tr>
                              <td>
                                <viewer rebuild :images="data.AnswerEventPhoto">
                                  <img
                                    style="padding: 5px"
                                    width="70"
                                    height="70"
                                    :src="src"
                                    :key="src"
                                    v-for="src in data.AnswerEventPhoto"
                                  />
                                </viewer>
                              </td>
                              <td>{{ data.AnswerEventDate }}</td>
                              <td>{{ data.AnswerPortfolioName }}</td>
                              <td>{{ data.AnswerEventName }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table> -->
                  </v-col>
                </v-row>
              </template>
              <br /><br />
            </v-col>
          </v-row>
        </v-form>

        <template v-if="CriteriaAnswers.length > 0">
          <v-divider></v-divider>
          <h3 class="text-center text-dark">Other Attachment Details</h3>
          <h6 class="text-dark mt-0">
            Add / Delete Attachment (max -
            {{ AllProjectMax }})
            <v-btn
              icon
              color="blue"
              v-if="AllProjectMax > AllProject.length"
              @click="addAttachmentNewRow()"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </h6>
          <br />
          <v-row v-if="AllProject.length > 0">
            <v-col
              v-for="(data, index) in AllProject"
              cols="12"
              sm="12"
              md="12"
              lg="3"
              xl="3"
              :key="index"
            >
              <v-card>
                <v-card-text>
                  <h6 class="text-right">
                    <v-btn icon color="red" @click="deleteAttachmentRow(index)">
                      <v-icon>mdi-delete-circle-outline</v-icon>
                    </v-btn>
                  </h6>
                  <h6 align="center">
                    <img
                      style="padding: 5px"
                      width="70"
                      height="70"
                      :src="data.AnswerEventImageUrl"
                    />
                  </h6>
                  <h6>
                    Event Date -
                    <span class="text-dark">{{ data.AnswerEventDate }}</span>
                  </h6>
                  <h6>
                    Event Name -
                    <span class="text-dark">{{ data.AnswerEventName }}</span>
                  </h6>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <div v-if="CriteriaAnswers.length > 0">
          <v-divider></v-divider>
          <h3 class="text-dark text-center mt-3">Video Details</h3>
          <v-row>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="text-danger">* Notes for Video File:</h5>
              <h6 class="text-dark">
                The video should be <br />
                &nbsp; &nbsp; 1. In MP4 or AVI or MOV file format <br />
                &nbsp; &nbsp; 2. Must be less than 5MB in file size
              </h6>
            </v-col>
          </v-row>
          <h4 class="text-dark mt-5">Upload Video <small>(optional)</small></h4>
          <v-row>
            <v-col cols="12" sm="12" lg="6" md="6">
              <v-file-input
                ref="UploadVideo"
                v-model="UploadVideo"
                :rules="UploadVideoRules"
                counter
                show-size
                truncate-length="47"
                :clearable="false"
                prepend-icon="mdi-video"
                accept="video/*"
                label="upload video here"
              >
                <template v-slot:append-outer>
                  <v-icon color="red" @click="resetUploadVideo">
                    mdi-delete
                  </v-icon>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <br /><br /><br /><br />
        </div>

        <v-row wrap v-if="ProgessStart == 1">
          <v-col align="center" cols="12" md="12">
            <v-progress-circular
              :rotate="180"
              :size="100"
              :width="15"
              :value="uploadPercentage"
              color="#00a4ef"
            >
              {{ uploadPercentage }}
            </v-progress-circular>
            <h3 class="red--text">
              Please wait, we are uploading your details. Time may vary based on
              your internet connection.
            </h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="center" cols="12" md="12">
            <!-- <v-btn
                @click="resetForm"
                elevation="30"
                rounded
                medium
                color="brown"
                class="font-size-h6 px-6 mr-3 white--text"
              >
                Reset
              </v-btn> -->
            <v-btn
              v-if="CriteriaAnswers.length > 0"
              @click.prevent="getTotalNatConIndividualAwardsDetails(4)"
              :loading="SubmitFlag"
              elevation="30"
              rounded
              medium
              color="#1db954"
              class="font-size-h6 px-6 mr-3 white--text"
            >
              Partial Save
            </v-btn>
            <v-btn
              v-if="CriteriaAnswers.length > 0"
              :disabled="!valid1"
              @click.prevent="getTotalNatConIndividualAwardsDetails(3)"
              :loading="SubmitFlag"
              elevation="30"
              rounded
              medium
              color="blue"
              class="font-size-h6 px-6 white--text"
            >
              Final Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt" persistent max-width="70%">
      <answer-attachment-create
        pageTitle="Add Project Details"
        :recordData="selectedRecord"
        :addRecordPrompt="addRecordPrompt"
        @hideAddRecordPrompt="hideAddRecordPrompt($event)"
        v-if="addRecordPrompt"
      ></answer-attachment-create>
    </v-dialog>

    <v-dialog v-model="addAttachmentRecordPrompt" persistent max-width="70%">
      <attachment-create
        pageTitle="Add Project Details"
        :addRecordPrompt="addAttachmentRecordPrompt"
        @hideAddRecordPrompt="hideAddAttachmentRecordPrompt($event)"
        v-if="addAttachmentRecordPrompt"
      ></attachment-create>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

import AnswerAttachmentCreate from "@/view/pages/erp/jci-award/natcon/common/AnswerAttachmentCreate.vue";
import MemberDetails from "@/view/pages/erp/jci-award/natcon/common/MemberDetails.vue";
import AttachmentCreate from "@/view/pages/erp/jci-award/natcon/common/AttachmentCreate.vue";

export default {
  mixins: [common],
  components: {
    AnswerAttachmentCreate,
    MemberDetails,
    AttachmentCreate,
  },
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,
      OverlayLoadingFlag: false,

      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      CardLoadingFlag: false,
      UploadFlag: false,
      addButtonFlag: true,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      Member: {},

      Status: "",

      NatconIndividualAwardStartDate: "",
      NatconIndividualAwardEndDate: "",
      TotalNatconIndividualAward: 0,
      TotalApplied: 0,
      TotalPartiallySaved: 0,

      StartDateFlag: false,
      EndDateFlag: false,

      UploadVideo: [],
      UploadVideoRules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Upload Video size should be less than 5 MB!",
      ],

      rows: {},
      AwardTypeTitleTxt: "",
      AwardTypeId: "",
      CriteriaAnswers: [],
      Project: [],
      selectedRecord: "",
      Id: "",
      showTextBox: false,

      addRecordPrompt: false,

      AllProjectMax: 10,
      AllProject: [],
      AllImages: [],
      addAttachmentRecordPrompt: false,
      ImageUploadFlag: false,

      AnswerRules: [(v) => !!v || "Answer is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var zone_name = this.$session.get("ZoneName");
        zone_name = zone_name == (null || undefined) ? 0 : zone_name;

        this.PageTitle = tr.PageTitle + " - " + zone_name;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.pageData();
      }
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    MemberId: function () {
      console.log("MemberId watch called");
      this.Member = {};
      this.getSelectedMemberDetails();
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "national_individual_award",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");

      var id = this.$route.query.id;
      console.log({ id });
      this.AwardTypeId = id;

      this.getAwardTypeDetials();
      this.getMemberIdOptions();
      this.getCriteriaAnswers();
    },
    resetUploadVideo() {
      console.log("resetUploadVideo called.");
      this.UploadVideo = "";
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 7,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getSelectedMemberDetails() {
      console.log("getSelectedMemberDetails called");
      this.Member = {};
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.Member = member;
      }
    },
    resetForm() {
      console.log("resetForm called");
      var rows = this.CriteriaAnswers;

      rows.map((row, index) => {
        row.AnswerText = "";
        row.AllProject = {};
      });

      this.getAwardTypeDetials();
      this.getCriteriaAnswers();
    },
    addNewRow(tr) {
      console.log("addNewRow called");
      console.log({ tr });
      this.selectedRecord = tr;
      this.addRecordPrompt = true;
    },
    deleteRow(id, index) {
      console.log("deleteRow called");
      console.log({ id });
      console.log({ index });
      if (index > -1) {
        var rows = this.CriteriaAnswers;

        rows.map((row, k) => {
          console.log({ k });
          if (id == k) {
            row.AllProject.splice(index, 1);

            // this.AllProject.splice(index, 1);
            // this.ProjectDetails = "";
          }
        });

        console.log("CriteriaAnswers = ", this.CriteriaAnswers);
        this.CriteriaAnswers = this.CriteriaAnswers;
      }
    },
    hideAddRecordPrompt(tr) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log({ tr });

      if (tr.EventId != "") {
        this.Id = tr.Index;
        var rows = this.CriteriaAnswers;

        // iterate foreach for CriteriaAnswers
        rows.map((row, index) => {
          console.log({ index });

          if (tr.Index == index) {
            var n2 = row.AllProject.length;
            console.log({ n2 });
            var n3 = row.CriteriaQuestionsAttachmentMax;
            console.log({ n3 });

            // For first Attachment
            if (n2 == 1) {
              // iterate foreach for AllProject

              row.AllProject.map((data, k) => {
                console.log({ k });
                var eventid = data.AnswerEventId;
                console.log({ eventid });
                if (eventid == "") {
                  row.AllProject.splice(k, 1);
                }

                var m1 = row.AllProject.length;
                console.log({ m1 });

                if (m1 < n3) {
                  row.AllProject.push({
                    AnswerEventId: tr.EventId,
                    AnswerEventName: tr.EventName,
                    AnswerEventDate: tr.EventDate,
                    AnswerPortfolioName: tr.PortfolioName,
                    AnswerEventPhoto: tr.EventPhoto,
                  });
                } else {
                  console.log("Maximum Limit Crossed");
                  var Message = "Attachment Maximum Count reached its limit.";
                  this.sweetAlert("error", Message, false);
                }
              });

              console.log("CriteriaAnswers = ", this.CriteriaAnswers);
              this.CriteriaAnswers = this.CriteriaAnswers;
            } else {
              if (n2 < n3) {
                // For after first Attachment
                row.AllProject.push({
                  AnswerEventId: tr.EventId,
                  AnswerEventName: tr.EventName,
                  AnswerEventDate: tr.EventDate,
                  AnswerPortfolioName: tr.PortfolioName,
                  AnswerEventPhoto: tr.EventPhoto,
                });
              } else {
                console.log("Maximum Limit Crossed");
                var Message = "Attachment Maximum Count reached its limit.";
                this.sweetAlert("error", Message, false);
              }
            }
          }
        });

        console.log("CriteriaAnswers = ", this.CriteriaAnswers);
        this.CriteriaAnswers = this.CriteriaAnswers;
      }
    },
    addAttachmentNewRow() {
      console.log("addAttachmentNewRow called");
      this.addAttachmentRecordPrompt = true;
    },
    deleteAttachmentRow(id) {
      console.log("deleteAttachmentRow called");
      console.log({ id });

      this.AllProject.splice(id, 1);
      this.AllImages.splice(id, 1);
    },
    hideAddAttachmentRecordPrompt(tr) {
      console.log("hideAddAttachmentRecordPrompt called");
      this.addAttachmentRecordPrompt = false;
      console.log({ tr });

      if (tr.EventName != "") {
        var count = this.AllProject.length;
        var id = count == 0 ? 0 : count + 1;

        this.AllProject.push({
          AnswerEventId: "",
          AnswerEventName: tr.EventName,
          AnswerEventDate: tr.EventDate,
          AnswerEventDescription: tr.EventDescription,
          AnswerEventImageName: tr.EventImageName,
          AnswerEventImageUrl: tr.EventImageUrl,
          AnswerEventImage: tr.EventImage,
        });

        this.AllImages.push({
          AnswerEventId: "",
          AnswerEventName: tr.EventName,
          UploadedImage: tr.EventImage.UploadedImage,
        });
      }
      console.log("AllProject = " + JSON.stringify(this.AllProject));
    },
    getAwardTypeDetials() {
      console.log("getAwardTypeDetials called");

      var validate1 = true;
      console.log({ validate1 });

      if (validate1) {
        this.CardLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/show";

        var upload = {
          UserInterface: 1,
          AwardType: this.AwardTypeId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CardLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.AwardTypeTitleTxt = records.AwardTypeTitleTxt;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.CardLoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    getCriteriaAnswers() {
      console.log("getCriteriaAnswers called");

      var AwardType = this.AwardTypeId;
      console.log({ AwardType });

      if (AwardType > 0) {
        this.tableData1 = [];
        this.selected1 = [];
        this.SubmitFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/judging-criteria/questions-lists";
        var upload = {
          UserInterface: 1,
          AwardType: AwardType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.CriteriaAnswers = records;
              // thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindy select the Award Type";
        this.toast("error", message);
      }
    },
    getTotalNatConIndividualAwardsDetails(status) {
      console.log("getTotalNatConIndividualAwardsDetails called");

      var validate1 = true;
      console.log({ validate1 });

      console.log({ status });
      this.Status = status;

      if (validate1) {
        this.CardLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/award-type/total-awards";

        var AwardCategory = 5;
        var AwardGroup = 1;

        if (this.RoleId == 2) {
          // For Admin
          var upload = {
            UserInterface: 1,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
            Lom: this.LomCode,
          };
        } else {
          // For Others
          var upload = {
            UserInterface: 1,
            AwardCategory: AwardCategory,
            AwardGroup: AwardGroup,
          };
        }

        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.CardLoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              console.log("records = " + JSON.stringify(records));
              thisIns.rows = records;
              thisIns.NatconIndividualAwardStartDate = records.StartDateTxt;
              thisIns.NatconIndividualAwardEndDate = records.EndDateTxt;
              thisIns.TotalNatconIndividualAward = records.TotalAward;
              thisIns.TotalApplied = records.TotalApplied;
              thisIns.TotalPartiallySaved = records.TotalPartiallySaved;
              thisIns.StartDateFlag = records.StartDateFlag;
              thisIns.EndDateFlag = records.EndDateFlag;

              console.log(
                "StartDateFlag=" +
                  records.StartDateFlag +
                  ", EndDateFlag=" +
                  records.EndDateFlag
              );

              if (thisIns.StartDateFlag && thisIns.EndDateFlag) {
                thisIns.confirmAlert(status);
              } else {
                thisIns.deadlineAlert();
              }
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            thisIns.CardLoadingFlag = false;
            console.log("error=" + error);
            thisIns.toast("error", error);
          });
      } else {
        var message = "";
        console.log("error=" + message);
      }
    },
    deadlineAlert() {
      console.log("deadlineAlert called");
      var htmlTxt = "";
      htmlTxt +=
        "<h3> Natcon Individual Awards Bidding ended at <b> " +
        this.NatconIndividualAwardEndDate +
        " </b> </h3>";
      // htmlTxt += "<br/> Start <b> " + this.NatconIndividualAwardEndDate + " </b>";

      Swal.fire({
        title: "Sorry !",
        text: "",
        icon: "warning",
        html: htmlTxt,
      });
    },
    confirmAlert(status) {
      console.log("confirmAlert called");
      var video_validate = false;
      var validate1 = false;
      var member_id = false;
      var photo_flag = false;

      video_validate =
        this.UploadVideo == "" ? true : this.$refs.UploadVideo.validate();
      validate1 = status == 4 ? true : this.$refs.form1.validate();
      member_id =
        this.MemberId == null || this.MemberId == "" ? "" : this.MemberId;
      photo_flag = this.Member.photo_flag ? true : false;

      console.log(
        { status },
        { video_validate },
        { validate1 },
        { member_id },
        { photo_flag }
      );

      if (video_validate && validate1 && member_id != "" && photo_flag) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            // Delete Empty event id in AllProject
            var rows = this.CriteriaAnswers;

            rows.map((row, index) => {
              console.log({ index });
              var n2 = row.AllProject.length;
              console.log({ n2 });
              if (n2 == 1) {
                // iterate foreach for AllProject

                row.AllProject.map((data, k) => {
                  console.log({ k });
                  var eventid = data.AnswerEventId;
                  console.log({ eventid });
                  if (eventid == "") {
                    row.AllProject.splice(k, 1);
                  }
                });
              }
            });

            console.log("CriteriaAnswers = ", this.CriteriaAnswers);
            this.CriteriaAnswers = this.CriteriaAnswers;

            this.submitForm(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", false);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "kindly answer all the questions.";
        }
        if (member_id == "") {
          message = "Kindly select Member";
        }
        if (!photo_flag) {
          message = "Member Profile not Updated.";
        }
        if (!video_validate) {
          message +=
            "The video should be <br/> 1. In MP4 or AVI or MOV file format <br/> 2. Must be less than 5MB in file size";
        }

        Swal.fire({
          // title: "Are you sure?",
          icon: "error",
          html: message,
        });
        // this.sweetAlert("error", message, false);
      }
    },
    submitForm(status) {
      console.log("submitForm is called");
      this.ProgessStart = 1;
      this.SubmitFlag = true;
      this.OverlayLoadingFlag = true;

      console.log({ status });
      var count = this.AllImages.length;

      var video = this.UploadVideo != {} ? this.UploadVideo : {};
      console.log({ video });

      this.AllImages.forEach((row, index) => {
        console.log({ index });
        row.AnswerEventId = index;
        console.log("AllImages=" + JSON.stringify(this.AllImages));
      });

      this.AllProject.forEach((item, index) => {
        console.log({ index });
        item.AnswerEventId = index;
        console.log("AllProject=" + JSON.stringify(this.AllProject));
      });

      var inputData = {
        ActiveStatus: status,
        Member: this.MemberId,
        AwardGroup: 1,
        AwardCategory: 5,
        AwardType: this.AwardTypeId,
        CriteriaAnswers: this.CriteriaAnswers,
        AllProject: this.AllProject,
        AllImagesCount: count,
      };
      console.log("inputData=" + JSON.stringify(inputData));

      let upload = new FormData();
      upload.append("UserInterface", 1);
      upload.append("InputData", JSON.stringify(inputData));
      upload.append("UploadVideo", video);

      this.AllImages.forEach((row, index) => {
        var id = index + 1;
        console.log({ id });
        var UploadedImage = row["UploadedImage"];
        var EventId = row["AnswerEventId"];
        console.log({ UploadedImage }, { EventId });

        upload.append("AllImages" + index, UploadedImage, EventId);
      });

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/award/national-award-store";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.ProgessStart = 0;
          thisIns.SubmitFlag = false;
          thisIns.OverlayLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.$router.push("/national/individual-award/list");
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.ProgessStart = 0;
          thisIns.SubmitFlag = false;
          thisIns.OverlayLoadingFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss"></style>
